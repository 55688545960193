import styled from 'styled-components';

const MessagesListStyled = styled.ul`
  .message-bubble-shape {
    display: inline-block;
    position: relative;
    padding: 8px 12px 0 12px;
    border-radius: 4px;
    max-width: 75%;
    min-width: 200px;
    line-height: 24px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 8px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 20px 0 0;
      border-color: #ffffff transparent transparent transparent;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 8px;
      display: inline-block;
      width: 0;
      height: 0;
      filter: blur(3px);
      border-style: solid;
      border-width: 8px 20px 0 0;
      border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
    }
    .message-text {
      font-size: 14px;
      padding-right: 12px;
      color: ${(props) => props.theme.colors.grey[100]};
    }
    .timespan-message {
      color: #979797;
      font-size: 10px;
      text-align: end;
    }
  }
  .message-bubble-shape-operator {
    display: inline-block;
    position: relative;
    padding: 8px 12px 0 12px;
    border-radius: 4px;
    max-width: 75%;
    min-width: 200px;
    line-height: 24px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      right: 8px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 15px 0;
      border-color: transparent #dcf8c6 transparent transparent;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -12px;
      right: 8px;
      display: inline-block;
      width: 0;
      height: 0;
      filter: blur(3px);
      border-style: solid;
      border-width: 0 20px 8px 0;
      border-color: transparent rgba(0, 0, 0, 0.2) transparent transparent;
    }

    .message-text {
      font-size: 14px;
      padding-right: 12px;
      color: ${(props) => props.theme.colors.grey[100]};
      text-align: start;
      word-wrap: break-word;
    }
    .timespan-message {
      color: #979797;
      font-size: 10px;
      text-align: end;
    }
  }

  .message-bubble-shape-bot {
    display: inline-block;
    position: relative;
    padding: 8px 12px 0 12px;
    border-radius: 4px;
    max-width: 75%;
    min-width: 200px;
    line-height: 24px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    text-align: right;
    align-items: flex-end;

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      right: 8px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 15px 0; 
      border-color: transparent #fde3a7 transparent transparent;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -12px;
      right: 8px;
      display: inline-block;
      width: 0;
      height: 0;
      filter: blur(3px);
      border-style: solid;
      border-width:0 20px 8px 0;
      border-color:  transparent rgba(0, 0, 0, 0.2)transparent transparent;
    }

    .message-text {
      font-size: 14px;
      padding-right: 12px;
      color: ${(props) => props.theme.colors.grey[100]};
    }
    .timespan-message {
      color: #979797;
      font-size: 10px;
      text-align: end;
    }
  }

  .message-bubble-color-input {
    background-color: white;
  }
  .message-bubble-color-operator {
    background-color: ${({ theme }) => theme.colors.outgoingBubble};
  }
  .message-bubble-color-bot {
    background-color: #fde3a7;
  }

  @media (max-width: ${({ theme }) => theme.bp.sm}px) {
    .message-bubble-shape-bot,
    .message-bubble-shape-operator,
    .message-bubble-shape {
      max-width: 85%;
    }
  }
`;

export { MessagesListStyled };
